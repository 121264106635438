import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const WebsitesContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Your Online Presence, Your Digital Identity</h2>
                        <p>Website creation is the foundation for establishing a strong online presence. At <strong>{process.env.REACT_APP_NAME}</strong>, we offer website creation services that not only represent your brand but also enhance the user experience.</p>
                        <h3>Custom Design</h3>
                        <p>We develop custom website designs that reflect the unique identity of your brand. Each element is carefully crafted to provide an engaging and consistent visual experience.</p>
                        <h3>Responsive Development</h3>
                        <p>We ensure that your website is fully responsive, providing a seamless user experience on all devices. This is essential for reaching a broad audience and ensuring accessibility.</p>
                        <h3>Advanced Features</h3>
                        <p>We implement advanced features to meet the specific needs of your business. Whether it's an e-commerce system, interactive forms, or integrations, we customize your website to achieve your goals.</p>
                    </>
                ) : (
                    <>
                        <h2>Sua Presença Online, Sua Identidade Digital</h2>
                        <p>A criação de sites é a base para estabelecer uma presença online sólida. Na <strong>{process.env.REACT_APP_NAME}</strong>, oferecemos serviços de criação de sites que não apenas representam sua marca, mas também aprimoram a experiência do usuário.</p>
                        <h3>Design Personalizado</h3>
                        <p>Desenvolvemos designs de sites personalizados que refletem a identidade única da sua marca. Cada elemento é cuidadosamente projetado para proporcionar uma experiência visual atraente e consistente.</p>
                        <h3>Desenvolvimento Responsivo</h3>
                        <p>Garantimos que seu site seja totalmente responsivo, proporcionando uma experiência de usuário perfeita em todos os dispositivos. Isso é essencial para atingir um público amplo e garantir a acessibilidade.</p>
                        <h3>Funcionalidades Avançadas</h3>
                        <p>Implementamos funcionalidades avançadas para atender às necessidades específicas do seu negócio. Seja um sistema de e-commerce, formulários interativos ou integrações, personalizamos seu site para alcançar seus objetivos.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default WebsitesContent;
