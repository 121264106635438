import React from 'react'
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper/modules';
import {Swiper, SwiperSlide } from "swiper/react";
import { Element } from 'react-scroll';

import brandImg1 from '../assets/images/brands/hyundai.jpg';
import brandImg2 from '../assets/images/brands/tenda.jpg';
import brandImg3 from '../assets/images/brands/lojas-cem.jpg';
import brandImg4 from '../assets/images/brands/petrobras.jpg';
import brandImg5 from '../assets/images/brands/dia.jpg';
import brandImg6 from '../assets/images/brands/oticas-carol.jpg';
import brandImg7 from '../assets/images/brands/veibras.jpg';
import brandImg8 from '../assets/images/brands/farma-ponte.jpg';
import brandImg9 from '../assets/images/brands/pernambucanas.jpg';
import brandImg10 from '../assets/images/brands/americanas.jpg';
import brandImg11 from '../assets/images/brands/vivo.jpg';
import brandImg12 from '../assets/images/brands/bv-financeira.jpg';
import brandImg13 from '../assets/images/brands/anhanguera.jpg';
import brandImg14 from '../assets/images/brands/sabesp.jpg';
import brandImg15 from '../assets/images/brands/help.jpg';
import brandImg16 from '../assets/images/brands/cacau-show.jpg';
import brandImg17 from '../assets/images/brands/apeoesp.jpg';
import brandImg18 from '../assets/images/brands/sesi.jpg';
import brandImg19 from '../assets/images/brands/atacadao.webp';
import brandImg20 from '../assets/images/brands/drogaria-sao-paulo.webp';
import brandImg21 from '../assets/images/brands/claro.webp';
import brandImg22 from '../assets/images/brands/montreal.webp';
import brandImg23 from '../assets/images/brands/serramar-shopping.webp';
import brandImg24 from '../assets/images/brands/shopping-jardim-oriente.webp';
import brandImg25 from '../assets/images/brands/caragua-praia-shopping.webp';
import brandImg26 from '../assets/images/brands/kashiura-papelaria.webp';
import brandImg27 from '../assets/images/brands/acdelco.webp';
import brandImg28 from '../assets/images/brands/unimed.webp';
import brandImg29 from '../assets/images/brands/jeep.webp';
import brandImg30 from '../assets/images/brands/edp.webp';

interface Brand {
    name: string;
    img: string;
}

type Props = {
    type?: string;
}	

const Brands = ({type}: Props) => {

	const { t } = useTranslation()
    
    const brands: Brand[] = [
        {
            name: 'Hyundai',
            img: brandImg1,
        },
        {
            name: 'Tenda Atacadão',
            img: brandImg2,
        },
        {
            name: 'Atacadão',
            img: brandImg19,
        },
        {
            name: 'Drogaria São Paulo',
            img: brandImg20,
        },
        {
            name: 'Lojas Cem',
            img: brandImg3,
        },
        {
            name: 'Petrobras',
            img: brandImg4,
        },
        {
            name: 'Dia Supermercados',
            img: brandImg5,
        },
        {
            name: 'Óticas Carol',
            img: brandImg6,
        },
        {
            name: 'Veibrás',
            img: brandImg7,
        },
        {
            name: 'Montreal',
            img: brandImg22,
        },
        {
            name: 'Farma Ponte',
            img: brandImg8,
        },
        {
            name: 'Pernambucanas',
            img: brandImg9,
        },
        {
            name: 'Americanas',
            img: brandImg10,
        },
        {
            name: 'Vivo',
            img: brandImg11,
        },
        {
            name: 'Unimed',
            img: brandImg28,
        },
        {
            name: 'Claro',
            img: brandImg21,
        },
        {
            name: 'BV Financeira',
            img: brandImg12,
        },
        {
            name: 'Anhanguera',
            img: brandImg13,
        },
        {
            name: 'Sabesp',
            img: brandImg14,
        },
        {
            name: 'Help! Loja de Crédito',
            img: brandImg15,
        },
        {
            name: 'Cacau Show',
            img: brandImg16,
        },
        {
            name: 'APEOESP',
            img: brandImg17,
        },
        {
            name: 'SESI',
            img: brandImg18,
        },
        {
            name: 'Serramar Parque Shopping',
            img: brandImg23,
        },
        {
            name: 'Shopping Jardim Oriente',
            img: brandImg24,
        },
        {
            name: 'Caraguá Praia Shopping',
            img: brandImg25,
        },
        {
            name: 'Kashiura Papelaria',
            img: brandImg26,
        },
        {
            name: 'ACDelco',
            img: brandImg27,
        },
        {
            name: 'Jeep',
            img: brandImg29,
        },
        {
            name: 'EDP',
            img: brandImg30,
        },
    ]

    const brandsList: string[] = [
        "Acdelco",
        "Anhanguera Universidade",
        "Unisa - Universidade",
        "Cruzeiro Do Sul Universidade",
        "Dia Supermercados",
        "Di Gaspi",
        "Governo Do Estado De São Paulo",
        "Pernambucanas",
        "Casas Bahia",
        "Tim",
        "Unimed (São José Dos Campos)",
        "Jeep",
        "Hyundai Caoa",
        "Chevrolet Gm",
        "Itavema Renault",
        "Autozone",
        "Bold Snacks",
        "Bradesco",
        "Cacau Show",
        "Sesi",
        "Cop - Centro Odontológico Do Povo",
        "Brasil Sorriso",
        "Help Loja De Crédito",
        "Delavia Pneus",
        "Desktop Internet",
        "Droga Raia",
        "Farma Total",
        "Edp Energia",
        "Pagseguro",
        "Transpetro",
        "Fisk",
        "Sky Tv",
        "Lojas Marabrás",
        "Magazine Luiza",
        "Lojas Marisa",
        "Sabesp",
        "1000 Valle Multimarcas",
        "A3 Otica",
        "Otica Ponto De Vista",
        "Casa De Carnes 2 Irmãos",
        "Casa De Carnes Santa Cristina 2",
        "Casa De Carnes Nascimento",
        "Casa De Carnes Do Pai",
        "Casa Do Celular",
        "Colégio Casulo",
        "Construtora Wilney Cardoso",
        "Catedral Divino Espírito Santo (Indaiá)",
        "Paróquia Santa Terezinha (Martim De Sá)",
        "Alfa Assistencia Funerária",
        "Americanet",
        "Dedama",
        "Acec (Associação Comercial De Caraguatatuba)",
        "Serramar Shopping",
        "Shopping Jardim Oriente",
        "Caraguá Praia Shopping",
        "Cartão De Todos",
        "Bortoluzzi Corretora De Seguros",
        "Jangada Flat Hotel",
        "Juferr - Ferro E Aço",
        "Atacadão Da Madeira Imperial",
        "Zelar Materiais Para Construção",
        "Atacadão Dos Congelados",
        "Auto Maia",
        "Tony Veículos",
        "Batatuba",
        "Bazar E Papelaria Seu Tião (Barranco Alto)",
        "Marujos Gastrobar",
        "Bella Calçados",
        "Big Brother Cirkus",
        "Las Vegas Cirkus",
        "Brasil Rusticos",
        "Brutal Suplementos",
        "Prefeitura Caraguatatuba",
        "Instituto Baia Dos Vermelhos",
        "Instituto Panorama",
        "Giga + Fibra",
        "Jocami",
        "Kashiura Papelaria",
        "Kidstock",
        "Siri Krikri",
        "Lojas Brasil",
        "Lojas Teddy",
        "Mercadinho Piratininga",
        "Supermercado Pereque",
        "Moacir Pneus",
        "Otica Golden Mix",
        "Ultranet",
        "Padaria E Confeitaria Segredo Dos Doces",
        "Deborah Barreto Academia",
        "Rensz Calçados",
    ]
    
    return (
        <>
            {type === 'content' ? (
                <>
                    <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 lg:gap-8">
                        {brands.map((item, index) => (
                            <img
                                src={item.img}
                                alt={item.name}
                                title={item.name}
                                key={index}
                                className="w-full rounded-md"
                            />
                        ))}
                    </div>

                    <div className="mt-4 mb-8 grid grid-cols-2 lg:grid-cols-3 w-full gap-4 lg:gap-8">
                        {brandsList.map((item, index) => (
                            <div
                                className="flex items-center justify-start text-sm lg:text-md font-semibold border-l-4 border-b-4 border-slate-400 p-2 px-4 h-full rounded-md bg-gray-50 text-slate-800"
                                key={index}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <Element
                    className="w-full"
                    name="customers"
                    id="brands"
                >
                    <Swiper
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2250,
                            disableOnInteraction: false
                        }}
                        spaceBetween={32}
                        loop={true}
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        breakpoints={{
                            0: {
                                slidesPerView: 2.5,
                            },
                            640: {
                                slidesPerView: 3.5,
                            },
                            768: {
                                slidesPerView: 4.5,
                            },
                            1024: {
                                slidesPerView: 6.5,
                            },
                        }}
                    >
                        {brands.map((item, index) => (
                            <SwiperSlide key={index}>
                                <img
                                    src={item.img}
                                    alt={item.name}
                                    className="p-0 w-full rounded shadow-md lg:shadow-lg shadow-slate-900"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Element>
            )}
        </>
    )
}

export default Brands