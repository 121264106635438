import React from 'react'
import { Element } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'
import ContactLine from '../components/ContactLine'
import TitleCol from '../components/TitleCol'
import Icon from '../components/Icon'
import RegisterForm from './RegisterForm'

const Contact = () => {

    const { t } = useTranslation()
    
    return (
        <Element className="w-full mt-16 lg:mt-24 mb-24" name="contact" id="contact">
            <Container>
                
                <TitleCol
                    title={t('contact.title')}
                    subtitle={t('contact.subtitle')}
                    className="mb-4 lg:mb-8"
                />
                
                <div className="bg-slate-800 rounded-md overflow-hidden">
                    <div className="grid lg:grid-cols-2 max-lg:gap-4">
                        <div className="text-white p-8">
                            <div
                                className="flex items-center justify-start flex-col lg:py-8 gap-4 lg:gap-6"
                                itemScope
                                itemType="https://schema.org/LocalBusiness"
                            >
                                <h2
                                    className="text-xl lg:text-4xl uppercase text-white mb-2 w-full font-bold font-roboto"
                                    itemProp="name"
                                >
                                    {process.env.REACT_APP_NAME}
                                </h2>
                               
                                <ContactLine
                                    title={t('contact.social-media')}
                                    className="flex items-center justify-start pt-2 flex-row gap-4"
                                >
                                    <a
                                        href="https://www.facebook.com/wdpublicidade.eventos"
                                        target="_blank"
                                    >
                                        <Icon icon="facebook" className="text-white hover:slate-100" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/wdpublicidade.eventos"
                                        target="_blank"
                                    >
                                        <Icon icon="instagram" className="text-white hover:slate-100" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCv-jCWQKfnjleTLdMouqxRA"
                                        target="_blank"
                                    >
                                        <Icon icon="youtube" className="text-white hover:slate-100" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/wd-publicidade-e-eventos"
                                        target="_blank"
                                    >
                                        <Icon icon="linkedin" className="text-white hover:slate-100" />
                                    </a>
                                </ContactLine>
                                
                                <ContactLine title={t('contact.email')}>
                                    <a
                                        href="mailto:contato@wdpublicidade.com.br"
                                        itemProp="email"
                                    >
                                        contato@wdpublicidade.com.br
                                    </a>
                                </ContactLine>
                                
                                <ContactLine title={t('contact.phone')}>
                                    <a
                                        href="tel:1238878324"
                                        itemProp="telephone"
                                    >
                                        (12) 3887-8324
                                    </a>
                                </ContactLine>
                                
                                <ContactLine title={t('contact.whatsapp')}>
                                    <a
                                        href="wa.me/551299730-170?text=Olá!+Gostaria+de+mais+informações+sobre+a+empresa!"
                                        itemProp="telephone"
                                        target="_blank"
                                    >
                                        (12) 99730-1700
                                    </a>
                                </ContactLine>
                                
                                <ContactLine title={t('contact.address')}>
                                    <a
                                        className="w-full hover:no-underline"
                                        itemProp="address"
                                        itemScope
                                        itemType="https://schema.org/PostalAddress"
                                        href="https://www.google.com/maps/dir//WD+Publicidade+e+Eventos+-+R.+Benedito+Severino+de+Castro,+712+-+Barranco+Alto,+Caraguatatuba+-+SP,+11670-150/@-23.690528,-45.448199,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x94cd63c506ac19f3:0xae33b1bf71f1b08d!2m2!1d-45.448199!2d-23.690528!3e0?entry=ttu"
                                        target="_blank"
                                    >
                                        <span itemProp="streetAddress">R. Benedito Severino de Castro, 712, Sala 01</span> - <span itemProp="addressRegion">Barranco Alto</span>
                                        <br /><span itemProp="addressRegion">Caraguatatuba - SP</span>
                                        <br />CEP <span itemProp="postalCode">11670-150</span>
                                    </a>
                                </ContactLine>
                            </div>
                        </div>
                        <div className="p-8">
                            <div className="p-8 bg-white rounded-md shadow-md">
                                <RegisterForm />
                            </div>
                        </div>
                    </div>
                    <div className="bg-blue-500 w-full">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14614.369839273155!2d-45.448199!3d-23.690528!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cd63c506ac19f3%3A0xae33b1bf71f1b08d!2sWD%20Publicidade%20e%20Eventos!5e0!3m2!1spt-BR!2sbr!4v1706112993797!5m2!1spt-BR!2sbr"
                            width="1200"
                            height="450"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            style={{ border: 0 }}
                            className="w-full h-[500px]"
                        >
                        </iframe>
                    </div>
                </div>
            </Container>
        </Element>
    )
}

export default Contact