import React from 'react'
import { Field, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import Label from './Label';

type Props = {
	label?: string;
	name: string;
	type?: string;
	placeholder?: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	mask?: string;
	formatChars?: {[key: string|number]: any};
}

const InputText = (props: Props) => {
	const randId = Math.random() + '-';

	const renderLabel = props.label ? (
		<Label htmlFor={randId + props.name}>
			{props.label}
		</Label>
	) : null;

	const renderInput = ({field, form}: {field: any, form: any}) => {
		const inputClass = `appearance-none block w-full bg-white text-gray-800 border-2 ${
			form.errors[props.name] && form.touched[props.name]
				? 'border-red-500'
				: 'border-gray-400'
			} rounded p-2 lg:p-3 leading-tight focus:outline-none focus:border-gray-900 focus:shadow-md`;
		
		if (props.mask) {
			return (
				<InputMask
					{...field}
					mask={props.mask}
					className={inputClass}
					formatChars={props.formatChars}
					id={randId + props.name}
				/>
			);
		} else {
			return (
				<>
					<input
						{...field}
						type={props.type}
						className={inputClass}
						id={randId + props.name}
					/>
				</>
			);	
		}
	};

 	return (
		<div className="w-full mb-4">
			{renderLabel}
			<Field name={props.name}>
			{({ field, form }: { field: any; form: any }) => (
				<div>
					{renderInput({field, form})}
					<ErrorMessage name={props.name} component="div" className="text-red-500 text-sm font-semibold" />
				</div>
			)}
			</Field>
		</div>
	)
}

InputText.defaultProps = {
	type: "text",
}

export default InputText;