import React from 'react'

type Props = {
    title: string,
    className?: string,
    children: React.ReactNode,
}

const ContactLine = (props: Props) => {
    return (
        <div className="w-full font-roboto">
            <h3 className="text-yellow-300 text-lg lg:text-xl uppercase font-bold">
                {props.title}
            </h3>
            <div className={`${props.className} text-white [&>*]:hover:underline`}>
                {props.children}
            </div>
        </div>
    )
}

ContactLine.defaultProps = {
    className: '',
}

export default ContactLine