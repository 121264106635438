import React, { useState, useCallback, useEffect, useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CountDownTimerGrid from './CountDownTimerGrid'

type Props = {
    end: number
}

type TimeLeft = {
    days: number
    hours: number
    minutes: number
    seconds: number
}

const CountDown = (props: Props) => {
    const { t } = useTranslation()
    const [start, setStart] = useState(Date.now())
    
    const calculateTimeLeft = () => {
        let difference = props.end - start
        let timeLeft: TimeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
    
        return timeLeft
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setTimeout(() => {
            setStart(Date.now());
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearTimeout(timer);
    });

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <CountDownTimerGrid duration={timeLeft.days} text={t('datetime.days')} />
            <CountDownTimerGrid duration={timeLeft.hours} text={t('datetime.hours')} />
            <CountDownTimerGrid duration={timeLeft.minutes} text={t('datetime.minutes')} />
            <CountDownTimerGrid duration={timeLeft.seconds} text={t('datetime.seconds')} />
        </div>
    )
}

export default CountDown