import React from "react";

interface ContainerProps {
    maxWidth: "sm" | "md" | "lg" | "xl" | "2xl";
    paddingBlock: number;
    children: React.ReactNode;
    className?: string;
}

const Container = ({ maxWidth, paddingBlock, className, children }: ContainerProps) => {
    const padding = `${paddingBlock}rem`;

    return (
        <div className="w-full flex items-top justify-center" style={{ paddingBlock: padding }}>
            <div className={`w-[1200px] max-w-[96%] relative ${className}`}>
                {children}
            </div>
        </div>
    );
};

Container.defaultProps = {
	maxWidth: "xl",
	paddingBlock: 0,
    className: "",
};

export default Container;
export { Container as SectionContainer}