import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import i18n from '../i18n'

type Props = {}

const About = (props: Props) => {

    const lang = i18n.language
    const { t } = useTranslation()

    return (
        <div className="mt-32 mb-32">
            <Helmet>
                <title>{t('about-us')} - {process.env.REACT_APP_NAME}</title>
                <meta name="description" content={t('about-us-description')} />
            </Helmet>
            <div className="w-full flex items-center justify-center flex-col gap-8 lg:gap-16">
                <Container>
                    <h1 className="text-center text-2xl md:text-4xl lg:text-6xl font-bold font-sans text-red-500 flex flex-col items-center justify-center gap-2 mb-4 lg:mb-8">{t('about-us')}</h1>
                    <div className="prose prose-headings:mb-2 prose-h1:font-semibold lg:prose-lg w-full border-2 border-gray-100 p-4 lg:p-8 rounded-md">
                    {lang === 'en' ? (
                        <>
                            <p><b>WD</b> was born through a young entrepreneur in 2009 at the age of just 15, in the city of Caraguatatuba - SP.</p>
                            <p>It started with the delivery of FLYERS DOOR TO DOOR, shortly thereafter, seeing the need of customers, added the SOUND BICYCLE, where the bicycle was rented from a church, paying a percentage of what it earned. Even before turning 18, with the increasing demand from customers asking for the option of SOUND CAR, We set up the First Sound Car with our PARENTS, which increased the challenges and clientele.</p>
                            <p>With the significant increase in customers, at 18, Licensed and with another car, we started serving neighboring cities such as São Sebastião, Ubatuba, and Ilhabela. Shortly after, we set up a base in São José dos Campos, where we also serve neighboring cities such as Jacareí, Caçapava, Taubaté, and other cities in the Paraíba Valley and the North/South Coast).</p>
                            <p>During this period, we also worked with birthday and wedding parties, providing sound, lighting, DJ, etc.</p>
                            <p>In 2017, it was another historic milestone, where we officially became an ADVERTISING AND EVENTS AGENCY, serving not only the PARAÍBA VALLEY AND NORTH COAST but also THE ENTIRE STATE OF SÃO PAULO.</p>
                            <p>We have evolved in many things, and CURRENTLY we have the LARGEST FLEET OF SOUND CARS in the Region (which is our flagship in the agency), we also work with ANNOUNCERS in STORES; PROMOTERS; PRODUCTION OF JINGLES AND COMMERCIAL SPOTS; SOCIAL MEDIA; PAID TRAFFIC (sponsored link on Google, Instagram, and Facebook); WEBSITE CREATION; RENTAL of POPCORN MACHINE, COTTON CANDY, TENT, SOUND SYSTEM, PROJECTION, and all STRUCTURE FOR EVENTS of small, medium, and large scale.</p>
                            <p>In short, WE ARE WD Advertising and Events, with 15 YEARS of Stories and over 20 Thousand Budgets, +15 Thousand customers served throughout the state of São Paulo, we continue to always seek internal and external improvements so that the delivery of results to our customers continues to be always satisfactory.</p>
                            <blockquote>
                                <p>“Come with us, let's go up!”</p>
                            </blockquote>
                            <p><b>WD</b> - Creating affordable connections between clients and companies!</p>
                        </>
                    ) : (
                        <>
                            <p>A <b>WD</b> nasceu através de um jovem empreendedor em 2009 na época com apenas 15 anos de idade, na cidade de Caraguatatuba - SP.</p>
                            <p>Iniciou com entrega de PANFLETOS PORTA A PORTA, logo em seguida vendo a necessidade dos clientes, acrescentou a BICICLETA DE SOM, onde a bicicleta era alugada de uma igreja, pagando porcentagem do que faturava. Antes mesmo dos 18 anos, com o aumento da demanda dos clientes pedindo opção de CARRO DE SOM, Montou juntamente com os PAIS o Primeiro Carro de som, onde aumentou os desafios e a clientela.</p>
                            <p>Com o aumento expressivos de clientes, 18 anos, Habilitado e mais um carro, iniciamos os atendimentos nas cidades vizinhas, como São Sebastião, Ubatuba e ilhabela, logo em seguida, montamos uma base em São josé dos Campos, onde também atendemos as cidades vizinhas como Jacareí, Caçapava, Taubaté e demais cidades do Vale do Paraíba e Litoral Norte/Sul).</p>
                            <p>Nesse período, trabalhamos também com festas de aniversário e casamento, fornecendo sonorização, iluminação, DJ etc.</p>
                            <p>Em 2017, foi mais um marco histórico, onde Viramos OFICIALMENTE uma AGÊNCIA DE PUBLICIDADE E EVENTOS, atendendo não só o VALE DO PARAÍBA E LITORAL NORTE mas como TODO ESTADO DE SÃO PAULO.</p>
                            <p>Evoluímos muito em muitas coisas, onde ATUALMENTE temos a MAIOR FROTA DE CARROS DE SOM da Região (Que é nosso carro chefe na agência), trabalhamos também com LOCUTORES em LOJAS; PROMOTORAS; PRODUÇÃO DE JINGLE E SPOTS COMERCIAIS; SOCIAL MÍDIA; TRÁFEGO PAGO (link patrocinado no Google, Instagram e Facebook); CRIAÇÃO DE SITES; LOCAÇÃO de MÁQUINA DE PIPOCA, ALGODÃO, TENDA, SONORIZAÇÃO, PROJEÇÃO e toda ESTRUTURA PARA EVENTOS de pequeno, médio e grande porte.</p>
                            <p>Enfim, SOMOS A WD publicidade e Eventos, com 15 ANOS de Histórias e mais de 20 Mil Orçamentos, + de 15 Mil clientes Atendidos em todo estado de São Paulo, continuamos sempre buscando melhorias internas e externas para que a entrega de resultado aos nossos clientes continuem sendo sempre satisfatórias.</p>
                            <blockquote>
                                <p>“Vem com a gente, vamos pra cima!”</p>
                            </blockquote>
                            <p><b>WD</b> - Criando conexões acessíveis entre clientes e empresas!</p>
                        </>
                    )}
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default About