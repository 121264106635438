import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const rent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Popcorn, Cotton Candy, and More to your events or actions</h2>
                        <p>Transform your events and promotional activities with our variety of food machines and services. We offer popcorn machine rental, cotton candy machine rental, and a range of options to make your experience unique and memorable.</p>
                        <h3>Popcorn Machine</h3>
                        <p>Delight your guests with the irresistible aroma of fresh popcorn. Our popcorn machine is a perfect addition to events of all sizes, providing a touch of fun and flavor.</p>
                        <h3>Cotton Candy Machine</h3>
                        <p>Sweeten your event with colorful and delicious cotton candy. Our cotton candy machine creates a festive atmosphere, being a popular choice for parties, fairs, and celebrations.</p>
                        <h3>Variety of Options</h3>
                        <p>In addition to popcorn and cotton candy, we offer a variety of machines to meet your specific needs. From ice cream machines to coffee stations, we customize the experience according to your event.</p>
                    </>
                ) : (
                    <>
                        <h2>Locação de equipamentos e serviços que irão transformar seu evento</h2>
                        <p>Transforme seus eventos e ações promocionais com nossa variedade de máquinas de alimentos e serviços. Oferecemos locação de máquina de pipoca, máquina de algodão doce e uma variedade de opções para tornar sua experiência única e memorável.</p>
                        <h3>Máquina de Pipoca</h3>
                        <p>Encante seus convidados com o irresistível aroma de pipoca fresca. Nosso máquina de pipoca é uma adição perfeita para eventos de todos os tamanhos, proporcionando um toque de diversão e sabor.</p>
                        <h3>Máquina de Algodão Doce</h3>
                        <p>Adoce seu evento com algodão doce colorido e delicioso. Nosso máquina de algodão doce cria uma atmosfera festiva, sendo uma escolha popular para festas, feiras e celebrações.</p>
                        <h3>Tendas Piramidais</h3>
                        <p>As tendas piramidais oferecem uma cobertura elegante e robusta para eventos, garantindo proteção contra intempéries. Suas estruturas são fáceis de montar e ideais para ambientes externos, aliando funcionalidade e estética.</p>
                        <h3>Kit Lixeiras</h3>
                        <p>O kit de lixeiras segue a coloração padrão da coleta seletiva, facilitando a separação de resíduos e contribuindo para a sustentabilidade do evento. Essencial para manter o local limpo e organizado, promovendo a conscientização ambiental.</p>
                        <h3>Unifila</h3>
                        <p>A unifila é um sistema de organização de filas que otimiza o espaço e reduz o tempo de espera, garantindo uma experiência mais agradável para os usuários. Ideal para eventos e locais com grande fluxo de pessoas, proporciona ordem e eficiência na movimentação.</p>
                        <h3>Cones</h3>
                        <p>Cones de sinalização são essenciais para a segurança e organização do trânsito no local do evento. Eles direcionam o fluxo de pessoas e veículos, prevenindo acidentes e garantindo que tudo ocorra de forma ordenada e segura.</p>
                        <h3>Cavalete</h3>
                        <p>Utilizados para delimitar áreas e direcionar o público, os cavaletes são fundamentais para a organização de eventos. Eles são versáteis, fáceis de transportar e montar, garantindo a eficiência na gestão de espaços e multidões.</p>
                        <h3>Iluminação em LED branca</h3>
                        <p>A iluminação em LED branca proporciona uma atmosfera moderna e acolhedora, além de ser econômica e sustentável. Ideal para eventos noturnos, ela realça a beleza do local e cria um ambiente agradável para os convidados.</p>
                        <h3>Sonorização</h3>
                        <p>Um sistema de sonorização de qualidade é crucial para o sucesso de qualquer evento, garantindo que o som seja claro e audível em todos os pontos. A sonorização apropriada enriquece a experiência, envolvendo o público no ambiente.</p>
                        <h3>DJ's</h3>
                        <p>DJs são responsáveis por animar o evento com músicas adequadas para cada ocasião, criando uma atmosfera vibrante e cativante. Eles adaptam o repertório ao público, mantendo a energia alta e garantindo a diversão dos participantes.</p>
                        <h3>Gerador de energia</h3>
                        <p>Um gerador de energia assegura a continuidade do evento em caso de falhas elétricas, fornecendo uma fonte de energia confiável. Essencial para evitar imprevistos, mantém os equipamentos funcionando e o evento ativo sem interrupções.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
}

export default rent