import React from 'react';
import { default as ServicesLoop } from '../partials/Services';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Services () {
	const { t } = useTranslation();

	return (
		<main id="main" className="my-24 lg:my-32">
			<Helmet>
				<title>{t('menu.services')} - {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={t('services.description')} />
			</Helmet>
			<ServicesLoop />
		</main>
	);
}
