import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const OutdoorContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Showcase Your Brand on a Grand Scale</h2>
                        <p>Want your message to be noticed by everyone passing through a specific area? Here at <strong>{process.env.REACT_APP_NAME}</strong>, we offer outdoor advertising services to highlight your brand on a grand scale.</p>
                        <h3>Visual Impact</h3>
                        <p>Our billboards are designed to create an immediate visual impact. We use creative design and compelling messages to ensure that your brand stands out amidst the urban landscape and captures the attention of the public.</p>
                        <h3>Strategic Locations</h3>
                        <p>We work to identify the best locations for your billboards, ensuring that your message reaches the target audience effectively. Whether in busy city areas or main roads, we maximize the visibility of your campaign.</p>
                        <h3>Customized Campaigns</h3>
                        <p>We collaborate with you to create customized campaigns that align with your brand identity. From seasonal promotions to institutional messages, our billboards are a powerful platform to communicate your messages.</p>
                    </>
                ) : (
                    <>
                        <h2>Destaque Sua Marca em Grandes Dimensões</h2>
                        <p>Quer que a sua mensagem seja notada por todos que passam por determinada região? Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, oferecemos serviços de outdoor para dar destaque à sua marca em grandes dimensões.</p>
                        <h3>Impacto Visual</h3>
                        <p>Nossos outdoors são projetados para causar um impacto visual imediato. Utilizamos design criativo e mensagens cativantes para garantir que sua marca se destaque entre a paisagem urbana e capture a atenção do público.</p>
                        <h3>Localização Estratégica</h3>
                        <p>Trabalhamos para identificar as melhores localizações para seus outdoors, garantindo que sua mensagem atinja o público-alvo de forma eficaz. Seja em áreas movimentadas da cidade ou estradas principais, maximizamos a visibilidade da sua campanha.</p>
                        <h3>Campanhas Personalizadas</h3>
                        <p>Colaboramos com você para criar campanhas personalizadas que se alinhem à identidade da sua marca. Desde promoções sazonais até mensagens institucionais, nossos outdoors são uma plataforma poderosa para comunicar suas mensagens.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default OutdoorContent;
