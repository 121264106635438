import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    color: 'red'|'blue'|'orange'|'green',
    icon?: string|null,
    title: string,
    description: string,
    className?: string,
}

const ColorBlock = (props: Props) => {

    const { t } = useTranslation();

    const classColors = {
        red: 'bg-red-500',
        blue: 'bg-blue-500',
        orange: 'bg-orange-500',
        green: 'bg-green-500',
    }

    return (
        <div className={`${props.className} bg-gray-100 relative font-roboto rounded-lg font-semibold 
                        overflow-hidden h-full lg:h-[260px] text-white transition-all duration-300 group
                        hover:cursor-pointer`}>
            <div className="w-full h-full p-4 flex items-start justify-center flex-col gap-2 text-gray-700 pr-16
                            transition duration-500 -translate-x-80 group-hover:translate-x-0 max-lg:hidden">
                <span className="text-slate-500 font-bold text-xs uppercase font-roboto transition duration-1000 -translate-x-80 group-hover:translate-x-0">
                    {props.title}
                </span>
                {props.description}
                <div className="px-2 py-1 text-sm bg-slate-700 rounded text-white uppercase transition duration-700 -translate-x-80 group-hover:translate-x-0">
                    {t('services.learn-more')}
                </div>
            </div>
            
            <div className={`${classColors[props.color]} lg:absolute lg:top-0 lg:left-0 w-full h-full flex flex-col 
                            items-start justify-end gap-4 p-4 z-10 transition duration-200 lg:group-hover:translate-x-60
                            group-hover:rotate-12 rounded-md shadow-lg`}>
                {props.icon &&
                    <figure className="w-16 h-16 rounded-md transition-all">
                        <img
                            src={props.icon}
                            alt={props.title}
                            className="w-full h-full object-contain"
                        />
                    </figure>
                }
                <div className="text-2xl lg:text-3xl font-bold">{props.title}</div>
                <small className="text-md text-normal lg:hidden">{props.description}</small>
            </div>
        </div>
    )
}

ColorBlock.defaultProps = {
	color: 'red',
    className: '',
    icon: null,
};

export default ColorBlock;
