import React from 'react'

type Props = {
    duration: number,
    text: string,
}

const CountDownTimerGrid = (props: Props) => {
    return (
        <div className="flex items-center flex-col justify-center bg-white rounded-md text-red-500 text-center shadow-md font-poppins overflow-hidden">
            <div className="w-full p-2 py-4 text-2xl lg:text-4xl font-bold">{props.duration <= 9 && 0}{props.duration}</div>
            <div className="w-full p-2 text-md lg:text-lg bg-gray-50">{props.text}</div>
        </div>
    )
}

export default CountDownTimerGrid