import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const BusinessAnalysisContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Driving Business Success</h2>
                        <p>Maximize your business potential with our business analysis consulting services. Our personalized approach helps companies identify growth opportunities and enhance their operations.</p>
                        <h3>Comprehensive Assessment</h3>
                        <p>Our experts conduct a comprehensive assessment of all aspects of your business. From financial analysis to process evaluation, we identify areas for improvement and efficiency.</p>
                        <h3>Growth Strategies</h3>
                        <p>We develop growth strategies tailored to the specific needs of your business. Whether expanding into new markets or optimizing existing operations, we are focused on driving long-term success.</p>
                        <h3>Process Optimization</h3>
                        <p>We implement practical solutions to optimize your internal processes. This includes the introduction of innovative technologies, team training, and continuous adjustments to ensure operational efficiency.</p>
                    </>
                ) : (
                    <>
                        <h2>Impulsionando o Sucesso Empresarial</h2>
                        <p>Maximize o potencial do seu negócio com nossos serviços de consultoria em análise de negócios. Nossa abordagem personalizada ajuda empresas e comércios a identificar oportunidades de crescimento e aprimorar suas operações.</p>
                        <h3>Avaliação Abrangente</h3>
                        <p>Nossos especialistas conduzem uma avaliação abrangente de todos os aspectos do seu negócio. Desde a análise financeira até a avaliação de processos, identificamos áreas de melhoria e eficiência.</p>
                        <h3>Estratégias de Crescimento</h3>
                        <p>Desenvolvemos estratégias de crescimento adaptadas às necessidades específicas do seu negócio. Seja expandindo para novos mercados ou otimizando operações existentes, estamos focados em impulsionar o sucesso a longo prazo.</p>
                        <h3>Otimização de Processos</h3>
                        <p>Implementamos soluções práticas para otimizar seus processos internos. Isso inclui a introdução de tecnologias inovadoras, treinamento da equipe e ajustes contínuos para garantir eficiência operacional.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default BusinessAnalysisContent;
