import React from 'react'
import { Element } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'

import VelocimeterIcon from '../assets/images/icons/velocimeter-icon.svg'
import SafetyIcon from '../assets/images/icons/safety-icon.svg'
import QualityIcon from '../assets/images/icons/quality-icon.svg'

type Feature = {
    title: string;
    description: string;
    icon: string|null;
}

type Props = {
    darkBg?: boolean;
}

const Features = (props: Props) => {
    
    const { t } = useTranslation()

    const features: Feature[] = [
        {
            title: t('features.feature1.title'),
            description: t('features.feature1.description'),
            icon: VelocimeterIcon,
        },
        {
            title: t('features.feature2.title'),
            description: t('features.feature2.description'),
            icon: SafetyIcon,
        },
        {
            title: t('features.feature3.title'),
            description: t('features.feature3.description'),
            icon: QualityIcon,
        },
    ];

    return (
        <Element
            className={`relative w-full ${props.darkBg && 'py-16 lg:py-16 xl:pb-24'}`}
            name="features"
            id="features"
        >
            {props.darkBg &&
                <div className="absolute bg-slate-800 top-0 left-0 w-full h-[180px] max-lg:hidden"></div>
            }
            
            <Container>
                <div className="grid md:grid-cols-3 gap-2 lg:gap-4">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className={`flex items-center ${props.darkBg ? 'bg-white' : 'bg-gray-50'} justify-start text-center p-4 lg:p-6 lg:border-b-2 lg:border-slate-50 rounded-md flex-col gap-4`}
                        >
                            {feature.icon &&
                                <img
                                    src={feature.icon}
                                    alt={feature.title}
                                    className="w-16 h-16"
                                />
                            }
                            <h3 className="text-2xl font-bold text-red-600 uppercase font-roboto">{feature.title}</h3>
                            <p className="text-gray-600 font-roboto">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </Container>
        </Element>
    )
}

Features.defaultProps = {
    darkBg: true,
}

export default Features