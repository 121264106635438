import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import Landing from './layouts/Landing'
import Home from './pages/Home'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Service from './pages/Service'
import Services from './pages/Services'
import Brands from './pages/Brands'
import Testimonials from './pages/Testimonials'
import Contact from './pages/Contact'
import About from './pages/About'
import ScrollToTop from './hooks/ScrollToTop'

const App: React.FC = () => {
	return (
		<>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						<Route index element={<Landing><Home /></Landing>} />
						<Route path='/privacy-policy' element={<Landing><PrivacyPolicy /></Landing>} />
						<Route path='/clientes' element={<Landing><Brands /></Landing>} />
						<Route path='/depoimentos' element={<Landing><Testimonials /></Landing>} />
						<Route path='/contato' element={<Landing><Contact /></Landing>} />
						<Route path='/sobre-nos' element={<Landing><About /></Landing>} />
						<Route path='/servicos'>
							<Route index element={<Landing><Services /></Landing>} />
							<Route path=':slug' element={<Landing><Service /></Landing>} />
						</Route>
					</Routes>
				</BrowserRouter>
			</I18nextProvider>
		</>
	);
}

export default App;
