import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/Container'
import BrandsComponent from '../partials/Brands'
import { Helmet } from 'react-helmet'
import i18n from '../i18n'
import TitleCol from '../components/TitleCol'
import Testimonials from '../partials/Testimonials'
import Contact from '../partials/Contact'

export default function Brands() {
	const { t } = useTranslation();

	return (
		<main id="main" className="pt-32">
            <Helmet>
            <title>{t('customers.title')} - {process.env.REACT_APP_NAME}</title>
                <meta name="description" content={t('customers.description')} />
            </Helmet>
			<Container className="flex items-center justify-center flex-col bb-16">
				<div className="w-full prose prose-headings:mb-2 prose-h1:font-semibold lg:prose-lg">
					<h1 className="text-center mb-4 lg:mb-8">{t('customers.page_title')}</h1>
					<div className="p-4 py-2 bg-white border-2 border-slate-200 rounded-md">
                        <h2>{t('customers.15years_title')}</h2>
                        <p>{t('customers.15years_subtitle')}</p>
                        
						<BrandsComponent type='content' />
					</div>
				</div>
			</Container>
			<Contact />
		</main>
	);
}
