import React from 'react'

type Props = {
	children: React.ReactNode,
}

const Prose = (props: Props) => {
    return (
        <div
            className="prose lg:prose-xl prose-headings:text-red-500 prose-headings:font-bold 
                       prose-headings:m-0 prose-headings:mb-2 prose-a:text-red-500 rounded-md 
                       bg-gray-50 p-8 lg:p-12"
        >
            {props.children}
        </div>
    )
}

export default Prose