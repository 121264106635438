import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const PaidTrafficContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Stand Out on Google and Social Media</h2>
                        <p>Boosting your online business requires strategic visibility. Here at <strong>{process.env.REACT_APP_NAME}</strong>, we offer paid traffic services on Google and social media to maximize your digital presence.</p>
                        <h3>Google Ads Campaigns</h3>
                        <p>Our Google Ads experts create effective campaigns that place your business at the top of search results. The qualified traffic generated through Google Ads boosts your conversions and increases brand visibility.</p>
                        <h3>Social Media Advertising</h3>
                        <p>Connect directly with your target audience through social media advertising campaigns. We use personalized strategies to ensure your message reaches the right people, generating engagement and increasing brand awareness.</p>
                        <h3>Continuous Optimization</h3>
                        <p>Our approach includes continuous optimization of campaigns to ensure the best return on investment. We monitor key metrics and adjust strategies as needed to drive positive results.</p>
                    </>
                ) : (
                    <>
                        <h2>Destaque-se no Google e Redes Sociais</h2>
                        <p>Impulsionar seu negócio online requer visibilidade estratégica. Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, oferecemos serviços de tráfego pago no Google e nas redes sociais para maximizar sua presença digital.</p>
                        <h3>Campanhas Google Ads</h3>
                        <p>Nossos especialistas em Google Ads criam campanhas eficazes que colocam seu negócio no topo dos resultados de pesquisa. O tráfego qualificado gerado por meio do Google Ads impulsiona suas conversões e aumenta a visibilidade da marca.</p>
                        <h3>Publicidade em Redes Sociais</h3>
                        <p>Conecte-se diretamente com seu público-alvo por meio de campanhas de publicidade nas redes sociais. Utilizamos estratégias personalizadas para garantir que sua mensagem alcance as pessoas certas, gerando engajamento e aumentando a notoriedade da marca.</p>
                        <h3>Otimização Contínua</h3>
                        <p>Nossa abordagem inclui a otimização contínua das campanhas para garantir o melhor retorno sobre o investimento. Monitoramos métricas-chave e ajustamos estratégias conforme necessário para impulsionar resultados positivos.</p>
                    </>
                )}
            </Prose>
        </Container>  
    );
};

export default PaidTrafficContent;
