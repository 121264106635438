import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const promoters = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Boosting Your Product or Service in the Market</h2>
                        <p>Our sales promoters are experts in highlighting your product or service, boosting sales, and increasing brand visibility. We offer personalized promotional solutions to meet the unique needs of your business.</p>
                        <h3>Proactive Approach</h3>
                        <p>Our team adopts a proactive approach to capture the attention of the target audience. From product demonstrations to interactive approaches, we create engaging experiences that encourage purchases.</p>
                        <h3>Specialized Promoters</h3>
                        <p>We have specialized promoters who understand the distinct characteristics of your product or service. This expertise enables effective communication and highlights unique benefits for consumers.</p>
                        <h3>Performance Reports</h3>
                        <p>We provide detailed performance reports for each campaign. This includes sales metrics, customer feedback, and valuable insights to enhance future strategies and maximize results.</p>
                    </>
                ) : (
                    <>
                        <h2>Impulsionando Seu Produto ou Serviço no Mercado</h2>
                        <p>Nossas promotoras de vendas são especialistas em destacar seu produto ou serviço, impulsionando vendas e aumentando a visibilidade da sua marca. Oferecemos soluções promocionais personalizadas para atender às necessidades exclusivas do seu negócio.</p>
                        <h3>Abordagem Proativa</h3>
                        <p>Nossa equipe adota uma abordagem proativa para atrair a atenção do público-alvo. Desde demonstrações de produtos até abordagens interativas, criamos experiências envolventes que incentivam a compra.</p>
                        <h3>Promotoras Especializadas</h3>
                        <p>Contamos com promotoras especializadas que entendem as características distintas do seu produto ou serviço. Essa especialização permite uma comunicação eficaz e destaca os benefícios exclusivos para os consumidores.</p>
                        <h3>Relatórios de Desempenho</h3>
                        <p>Fornecemos relatórios detalhados de desempenho para cada campanha. Isso inclui métricas de vendas, feedback do cliente e insights valiosos para aprimorar estratégias futuras e maximizar resultados.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
}

export default promoters