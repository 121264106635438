import React, {useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import Typewriter from 'typewriter-effect';

import HeaderBG from '../assets/images/header-bg.png';
import Container from '../components/Container';

const HomeHeader = () => {
	const { t } = useTranslation();

	const phrases = t('header.phrases', { returnObjects: true }) as string[];

	return (
		<div className="h-screen w-full overflow-hidden bg-black">
			<Container className="h-screen">
				<div className="z-10 absolute top-0 left-0 w-full h-full flex items-center lg:items-start flex-col justify-center uppercase text-white font-bold text-center">
					<div className="text-2xl lg:text-4xl xl:text-5xl m-0 p-0 w-full max-lg:h-full max-lg:flex max-lg:items-center max-lg:justify-center max-lg:flex-col lg:max-w-[650px]">
						{t('header.title')}
						{' '}
						<Typewriter
							options={{
								strings: phrases,
								autoStart: true,
								loop: true,
								delay: 40,
								deleteSpeed: 25,
							}}
						/>
					</div>
				</div>
			</Container>
			
			<img
				src={HeaderBG}
				alt={process.env.REACT_APP_NAME}
				className="absolute top-0 left-0 w-full h-screen object-cover z-0 max-lg:object-position-left"
			/>

			<div className="absolute top-0 left-0 w-full h-screen bg-gradient-to-r from-black to-transparent z-0"></div>
		</div>
	)
}

export default HomeHeader;