import React from 'react'

import Container from './Container'

type Props = {
    title: string,
    subtitle?: string,
    className?: string,
    mode: "light" | "dark",
}

const TitleCol = (props: Props) => {

    const titleClass = props.mode === "light" ? "text-gray-800" : "text-white"
    const subtitleClass = props.mode === "light" ? "text-gray-500" : "text-gray-300"

    return (
        <div className={`w-full font-roboto font-bold ${props.className}`}>
            <Container>
                <div className="w-full flex flex-col justify-center items-center gap-4">
                    <h2 className={`text-2xl lg:text-3xl xl:text-4xl ${titleClass}`}>{props.title}</h2>
                    {props.subtitle &&
                        <small className={`text-lg max-w-[720px] text-center font-normal italic ${subtitleClass}`}>{props.subtitle}</small>
                    }
                </div>
            </Container>
        </div>
    )
}

TitleCol.defaultProps = {
    mode: "light",
    className: "",
}

export default TitleCol