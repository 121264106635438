import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import TitleRow from '../components/TitleRow';
import { Element } from 'react-scroll';
import Brands from './Brands';

import { Pagination, Autoplay } from 'swiper/modules';
import {Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';

import AboutImg1 from '../assets/images/about/about-banner-1.png';
import AboutImg2 from '../assets/images/about/about-banner-2.png';
import AboutImg3 from '../assets/images/about/about-banner-3.png';

const About = () => {

	const { t } = useTranslation();

	const slides = [
		{
			title: t('about.slides.slide1.title'),
			description: t('about.slides.slide1.description'),
			image: AboutImg1,
		},
		{
			title: t('about.slides.slide2.title'),
			description: t('about.slides.slide2.description'),
			image: AboutImg2,
		},
		{
			title: t('about.slides.slide3.title'),
			description: t('about.slides.slide3.description'),
			image: AboutImg3,
		}
	];

	return (
		<Element
			className="w-full bg-gradient-to-b from-black to-gray-800 py-16 flex items-center justify-center flex-col gap-8 lg:gap-16"
			name="about"
			id="about"
		>
			<TitleRow
				title={t('about.15years_title')}
				subtitle={t('about.15years_subtitle')}
				mode="dark"
				className="max-lg:p-4 lg:pt-12"
			/>
			<Brands />
			<TitleRow
				title={t('about.title')}
				subtitle={t('about.subtitle')}
				mode="dark"
				className="max-lg:p-4 lg:pt-12"
			/>
		</Element>
 	);
}

export default About