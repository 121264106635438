import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import i18n from '../i18n';
import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
	const { t } = useTranslation();

	return (
		<main id="main" className="pt-32 bg-gray-50">
			<Helmet>
				<title>{t('privacy-policy')} - {process.env.REACT_APP_NAME}</title>
				<meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
			</Helmet>
			<Container className="flex items-center justify-center flex-col">
				<div className="w-full prose lg:prose-lg">
					<h1 className="text-center">{t('privacy-policy')}</h1>
					<div className="p-4 py-2 bg-white border-2 border-b-0 border-slate-200 rounded-t-md">
						{i18n.language === 'en' ? (
							<>
								<p>Your privacy is important to us. It is WD Publicidade's policy to respect your privacy regarding any information we may collect from you on the WD Publicidade website, and other sites we own and operate.</p>
								<p>We only request personal information when we truly need it to provide you with a service. We do so by fair and legal means, with your knowledge and consent. We also tell you why we are collecting it and how it will be used. We only retain collected information for as long as necessary to provide the requested service. When we store data, we protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. We do not share personally identifiable information publicly or with third parties except when required by law.</p>
								<p>Our website may have links to external websites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility for their respective privacy policies.</p>
								<p>You are free to refuse our request for personal information, understanding that we may not be able to provide some of the desired services.</p>
								<p>Your continued use of our website will be considered as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, please contact us.</p>
								<p>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times a given ad is shown to you.</p>
								<p>For more information about Google AdSense, see the official Google AdSense privacy FAQ.</p>
								<p>We use ads to offset the costs of operating this site and provide funding for future developments. The behavioral advertising cookies used by this site are designed to ensure that we provide you with the most relevant ads whenever possible by anonymously tracking your interests and presenting similar things that may be of interest to you.</p>
								<p>Several partners advertise on our behalf and affiliate tracking cookies simply allow us to see if our customers have accessed the site through one of our partner sites, so that we can credit them appropriately and, when applicable, allow our affiliate partners to provide any promotion that may provide you to make a purchase.</p>
								<h2>User commitment</h2>
								<p>The user undertakes to make proper use of the contents and information that WD Publicidade offers on the site and with an enunciative but not limited character:</p>
								<ol>
									<li>Do not engage in activities that are illegal or contrary to good faith and public order;</li>
									<li>Do not disseminate propaganda or content of a racist, xenophobic, pixbet or gambling nature, any type of illegal pornography, in support of terrorism or against human rights;</li>
									<li>Do not cause damage to the physical (hardware) and logical (software) systems of WD Advertising, its suppliers or third parties, to introduce or disseminate computer viruses or any other hardware or software systems that are capable of causing the aforementioned damages. </li>
								</ol>
								<h2>More Information</h2>
								<p>Hopefully that's clear, and as mentioned previously, if there's something you're not sure whether you need or not, it's usually safer to leave cookies enabled in case it interacts with one of the features you use on our site.</p>
								<p>This policy is effective from December 11, 2020, 7:29 pm.</p>
							</>
						) : (
							<>
								<p>A sua privacidade é importante para nós. É política do WD Publicidade respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site WD Publicidade, e outros sites que possuímos e operamos.</p>
								<p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
								<p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
								<p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
								<p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contacto connosco.</p>
								<p>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.</p>
								<p>Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</p>
								<p>Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</p>
								<p>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.</p>
								<h2>Compromisso do Usuário</h2>
								<p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o WD Publicidade oferece no site e com caráter enunciativo, mas não limitativo:</p>
								<ol>
									<li>Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
									<li>Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, pixbet ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
									<li>Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do WD Publicidade, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li>
								</ol>
								<h2>Mais Informações</h2>
								<p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.</p>
								<p>Esta política é efetiva a partir de 11 de Dezembro de 2020, 19:29.</p>
							</>
						)}
					</div>
				</div>
			</Container>
		</main>
	);
}
