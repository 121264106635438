import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const RadioService = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Reach Millions with Captivating Messages</h2>
                        <p>Want to bring your message to millions of listeners? Here at <strong>{process.env.REACT_APP_NAME}</strong>, we offer radio advertising services to ensure that your brand is heard by a diverse and engaged audience.</p>
                        <h3>Persuasive Ads</h3>
                        <p>Our advertising experts create persuasive ads that highlight the strengths of your brand. From engaging calls to action to institutional messages, we ensure that your message is conveyed in an impactful way.</p>
                        <h3>Strategic Station Selection</h3>
                        <p>We work on selecting strategic radio stations that reach your target audience. Whether for a regional or national campaign, we adapt our approach to ensure maximum effectiveness in your radio strategy.</p>
                        <h3>Performance Tracking</h3>
                        <p>We use advanced analytical tools to track the performance of your radio campaigns. This allows us to make continuous adjustments to optimize impact and ensure positive results.</p>
                    </>
                ) : (
                    <>
                        <h2>Alcance Milhões com Mensagens Cativantes</h2>
                        <p>Quer levar sua mensagem a milhões de ouvintes? Aqui na WD Publicidade e Eventos, oferecemos serviços de criação e produção de SPOT, JINGLE e VINHETA para garantir que sua marca seja ouvida por uma audiência diversificada e engajada.</p>
                        <h3>Spot/Vinheta</h3>
                        <p>Podemos definir um spot publicitário como uma propaganda veiculada no rádio, carro de som, TV etc. Geralmente, os spots têm em média um tempo de duração de 15, 30, 45 ou 60 segundos. Resumidamente, é o "Cartão de visita em áudio" da sua empresa.</p>
                        <blockquote>O Spot é entregue em até 24 horas, com diversas opções: mais de 300 locutores, entre voz masculina, feminina, caricata e infantil.</blockquote>
                        <h3>Jingle/Paródia</h3>
                        <p>Jingle  é a "Propaganda cantada" exclusiva da sua Empresa, de fácil memorização para ser cantarolada pelos ouvintes, ou seja as "Chicletinhas" que não saem da cabeça, já a PARÓDIA, é feita em cima de uma letra já existente (Música do momento) e alterada a letra conforme a necessidade da campanha, facilitando ainda mais o reconhecimento e memorização.</p>
                        <h3>Anúncios Persuasivos</h3>
                        <p>Nossos especialistas em publicidade criam anúncios persuasivos que destacam os pontos fortes da sua marca. Desde chamadas para ação envolventes até mensagens institucionais, garantimos que sua mensagem seja transmitida de maneira impactante.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default RadioService;
