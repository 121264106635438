import React from 'react'
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const SocialMediaContent = () => {
    const lang = i18n.language;
    
    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Social Media: Maximizing Your Online Presence</h2>
                        <p>Have you ever considered how crucial online presence is in today's world? In the digital era, visibility on social media plays a pivotal role in the success of any business. Here at <strong>{process.env.REACT_APP_NAME}</strong>, we understand the importance of a well-crafted social media strategy.</p>
                        <h3>Connecting with Your Target Audience</h3>
                        <p>Our social media experts excel at creating meaningful connections between brands and their target audiences. We delve deep into your business profile to develop a personalized strategy that not only boosts your presence but also generates authentic engagement.</p>
                        <h3>Content that Shines</h3>
                        <p>In a world saturated with information, standing out is paramount. Our content creation team is adept at producing visually engaging and textually captivating materials. From striking visuals to persuasive texts, we ensure that each piece of content reflects the unique essence of your brand.</p>
                        <h3>Continuous Measurement and Enhancement</h3>
                        <p>The world of social media is ever-evolving. We utilize advanced analytics tools to monitor the performance of your campaigns in real-time. This allows us to adjust strategies as needed, ensuring exceptional results in the long run.</p>
                    </>
                ): (
                    <>
                        <h2>Maximizando sua Presença Online</h2>
                        <p>Você já parou para pensar como a presença online é crucial nos dias de hoje? Na era digital, a visibilidade nas redes sociais desempenha um papel fundamental no sucesso de qualquer negócio. Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, compreendemos a importância de uma estratégia de mídia social bem elaborada.</p>
                        <h3>Conectando-se com seu Público-alvo</h3>
                        <p>Nossos especialistas em mídia social são mestres em criar conexões significativas entre marcas e seus públicos-alvo. Analisamos profundamente o perfil do seu negócio para desenvolver uma estratégia personalizada que não apenas aumenta sua presença, mas também gera engajamento autêntico.</p>
                        <h3>Conteúdo que Resplandece</h3>
                        <p>Em um mundo inundado de informações, é crucial se destacar. Nossa equipe de criação de conteúdo é habilidosa em produzir materiais visualmente envolventes e textualmente cativantes. Desde imagens impactantes até textos persuasivos, garantimos que cada peça de conteúdo reflita a essência única da sua marca.</p>
                        <h3>Mensuração e Aperfeiçoamento Constantes</h3>
                        <p>O mundo das redes sociais está sempre evoluindo. Utilizamos ferramentas analíticas avançadas para monitorar o desempenho das suas campanhas em tempo real. Isso nos permite ajustar estratégias conforme necessário, garantindo resultados excepcionais a longo prazo.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default SocialMediaContent