import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const LocutionContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Captivating Your Customers with PDV Locution</h2>
                        <p>Want to create an engaging atmosphere in your store or business? Here at <strong>{process.env.REACT_APP_NAME}</strong>, we offer in-store announcer services to provide a unique auditory experience for your customers.</p>
                        <h3>Professional Voice</h3>
                        <p>Our professional announcers ensure an engaging and professional voice that conveys your message clearly. From promotional announcements to product information, we create an appealing sound environment for your customers.</p>
                        <h3>Customized Script</h3>
                        <p>We work together to create customized scripts that align with your brand identity. We adapt the language and tone to suit your target audience, ensuring effective communication.</p>
                        <h3>Strategic Timing</h3>
                        <p>We plan broadcast times to ensure that messages are delivered at the most strategic moments. Whether for seasonal promotions or relevant information, our in-store announcer service optimizes the impact of your sound communication.</p>
                    </>
                ) : (
                    <>
                        <h2>Cativando seus Clientes com Locutor PDV</h2>
                        <p>Quer criar uma atmosfera envolvente em sua loja ou comércio? Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, oferecemos serviços de locutor em lojas para proporcionar uma experiência auditiva única aos seus clientes.</p>
                        <h3>Voz Profissional</h3>
                        <p>Nossos locutores profissionais garantem uma voz envolvente e profissional que transmite sua mensagem de forma clara. Desde anúncios promocionais até informações sobre produtos, criamos um ambiente sonoro atraente para seus clientes.</p>
                        <h3>Script Personalizado</h3>
                        <p>Trabalhamos em conjunto para criar scripts personalizados que se alinhem à identidade da sua marca. Adaptamos a linguagem e o tom de voz para atender ao seu público-alvo, garantindo uma comunicação eficaz.</p>
                        <h3>Horários Estratégicos</h3>
                        <p>Planejamos os horários de transmissão para garantir que as mensagens sejam transmitidas nos momentos mais estratégicos. Seja para promoções sazonais ou informações relevantes, nosso serviço de locutor em lojas otimiza o impacto da sua comunicação sonora.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default LocutionContent;
