import React from 'react';
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const LandingPageContent = () => {
    const lang = i18n.language;

    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Converting Visitors into Customers</h2>
                        <p>Your landing page is the first impression visitors have of your online business. Here at <strong>{process.env.REACT_APP_NAME}</strong>, we understand the importance of an effective landing page to drive conversions.</p>
                        <h3>Attractive and Responsive Design</h3>
                        <p>Our design team creates attractive and responsive landing pages that stand out on any device. We ensure a visually engaging experience that captivates visitors from the very first moment.</p>
                        <h3>Persuasive Content</h3>
                        <p>In addition to design, we focus on persuasive content that guides visitors to take the desired action. Impactful copy and strategically placed calls to action are essential for converting visitors into customers.</p>
                        <h3>Performance Analysis</h3>
                        <p>We use advanced analytical tools to monitor the performance of your landing page. This allows us to make continuous adjustments to optimize conversion and ensure positive results.</p>
                    </>
                ) : (
                    <>
                        <h2>Convertendo Visitantes em Clientes</h2>
                        <p>Sua página de destino é a primeira impressão que os visitantes têm do seu negócio online. Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, entendemos a importância de uma landing page eficaz para impulsionar conversões.</p>
                        <h3>Design Atraente e Responsivo</h3>
                        <p>Nossa equipe de design cria landing pages atraentes e responsivas que se destacam em qualquer dispositivo. Garantimos uma experiência visual envolvente que cativa os visitantes desde o primeiro momento.</p>
                        <h3>Conteúdo Persuasivo</h3>
                        <p>Além do design, focamos em conteúdo persuasivo que leva os visitantes a tomar a ação desejada. Textos impactantes e chamadas para ação estrategicamente colocadas são essenciais para converter visitantes em clientes.</p>
                        <h3>Análise de Desempenho</h3>
                        <p>Utilizamos ferramentas analíticas avançadas para monitorar o desempenho da sua landing page. Isso nos permite fazer ajustes contínuos para otimizar a conversão e garantir resultados positivos.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default LandingPageContent;
