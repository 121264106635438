import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import i18n from '../i18n'
import TestimonialsComponent from '../partials/Testimonials'
import Contact from '../partials/Contact'

export default function Testimonials() {
	const { t } = useTranslation();

	return (
		<main id="main" className="pt-32">
            <Helmet>
                <title>{t('menu.testimonials')} - {process.env.REACT_APP_NAME}</title>
            </Helmet>
			<TestimonialsComponent />
			<Contact />
		</main>
	);
}
