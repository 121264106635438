import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import TitleRow from '../components/TitleRow';
import ColorBlock from '../components/ColorBlock';
import Container from '../components/Container';

import GrowthChartIcon from '../assets/images/icons/growth-chart-icon.svg';
import MegaphoneIcon from '../assets/images/icons/megaphone-icon.svg';
import SocialMediaIcon from '../assets/images/icons/social-media-icon.svg';
import WebsiteIcon from '../assets/images/icons/website-icon.svg';
import OutddoorIcon from '../assets/images/icons/outdoor-icon.svg';
import MicrophoneIcon from '../assets/images/icons/microphone-icon.svg';
import RadioIcon from '../assets/images/icons/radio-icon.svg';
import BusinessAnalysisIcon from '../assets/images/icons/business-analysis-icon.svg';
import InterviewIcon from '../assets/images/icons/interview-icon.svg';
import PartyIcon from '../assets/images/icons/party-icon.svg';
import LandingPageIcon from '../assets/images/icons/landing-page-icon.svg';
import WomanIcon from '../assets/images/icons/woman-icon.svg';

import services from '../data/services';
import categories, {Category as CategoryInterface, CategoriesList, ColorsList} from '../data/categories';

type Category = CategoryInterface;

const Services = () => {
 
    const { t } = useTranslation();

    const [ActiveCateory, SetActiveCategory] = React.useState('all');

    const handleCategory = (category: string) => {
        const categoryBtnShowAll = document.getElementsByClassName('category-btn-show-all')[0];

        if (category === 'all') {
            const categoryBlocks = Array.from(document.getElementsByClassName('category-block'));
            
            categoryBlocks.forEach((element: any) => {
                element.classList.remove('hidden');
            });

            categoryBtnShowAll.classList.add('hidden');
        } else {
            const categoryBlocks = Array.from(document.getElementsByClassName('category-block'));
            
            categoryBlocks.forEach((element: any) => {
                if (element.classList.contains(`category-block-${category}`)) {
                    element.classList.remove('hidden');
                } else {
                    element.classList.add('hidden');
                }
            });

            categoryBtnShowAll.classList.remove('hidden');
        }

        SetActiveCategory(category);
    }

    const categories: {[key: string]: Category} = {
        online: {
            name: t('services.categories.online'),
            color: 'red',
            bg: 'bg-red-500',
            slug: 'online',
        },
        offline: {
            name: t('services.categories.offline'),
            color: 'blue',
            bg: 'bg-blue-500',
            slug: 'offline',
        },
        consulting: {
            name: t('services.categories.consulting'),
            color: 'orange',
            bg: 'bg-orange-500',
            slug: 'consulting',
        },
        events: {
            name: t('services.categories.events'),
            color: 'green',
            bg: 'bg-green-500',
            slug: 'events',
        },
    };
    
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.875)',
            zIndex: 200,
        },
    };
    
    return (
        <>
            <Element
                name="services"
                className="max-lg:py-8 w-full flex flex-col p-2 md:p-4 lg:p-8 items-center justify-center gap-8 lg:gap-p16"
                id="services"
            >
                
                <TitleRow
                    title={t('services.title')}
                    subtitle={t('services.subtitle')}
                />

                <Container>
                    <div className="lg:flex lg:items-center lg:justify-start lg:flex-row gap-2 lg:gap-4 mb-4 max-lg:grid max-lg:grid-cols-2 max-lg:text-center">
                        {Object.keys(categories).map((category, index) => (
                            <div
                                key={index}
                                className={`${categories[category].bg} py-2 px-4 text-white rounded-md uppercase font-bold font-roboto 
                                            hover:shadow-md hover:cursor-pointer lg:hover:-translate-y-1 transition duration-300`}
                                onClick={() => handleCategory(categories[category].slug ?? 'all')}
                            >
                                {categories[category].name}
                            </div>
                        ))}
                        <div
                            className="category-btn-show-all rounded-md py-2 px-4 text-slate-800 uppercase font-bold font-roboto 
                                        hover:bg-slate-100 hover:cursor-pointer transition duration-300 hidden max-lg:col-span-2"
                            onClick={() => handleCategory('all')}
                        >
                            {t('services.show-all')}
                        </div>
                    </div>

                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-4">
                        {services.map((service, index) => (
                            <Link
                                key={index}
                                className="w-full h-full"
                                to={`/servicos/${service.url}`}
                            >
                                <ColorBlock
                                    color={categories[service.category].color}
                                    title={t(service.title)}
                                    description={t(service.description)}
                                    icon={service.icon}
                                    className={`category-block category-block-${service.category}`}
                                />
                            </Link>
                        ))}
                    </div>
                </Container>
            </Element>
        </>
    )
}

export default Services