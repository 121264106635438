import React from 'react'
import i18n from '../../i18n';
import Container from '../../components/Container';
import Prose from '../../components/page/Prose';

const EventsContent = () => {
    const lang = i18n.language;
    
    return (
        <Container>
            <Prose>
                {lang === 'en' ? (
                    <>
                        <h2>Events: Creating Memorable Experiences</h2>
                        <p>Are you ready to transform your event into something truly unforgettable? Here at <strong>{process.env.REACT_APP_NAME}</strong>, we understand the importance of every detail in creating memorable experiences.</p>
                        <h3>Customized Planning</h3>
                        <p>Our dedicated team works side by side with you to create a personalized plan that meets your specific needs. From concept to execution, we are committed to ensuring that every detail is perfect.</p>
                        <h3>Unique Experiences</h3>
                        <p>We believe that each event should be unique. Whether it's a wedding, a corporate conference, or a social gathering, we strive to add distinctive elements that make the experience unique and memorable for participants.</p>
                        <h3>Experienced Professionals</h3>
                        <p>Our team consists of experienced professionals who understand the importance of a successful event. We manage all aspects, from logistics to on-the-day coordination, to ensure a flawless outcome.</p>
                    </>
                ): (
                    <>
                        <h2>Criando Experiências Memoráveis</h2>
                        <p>Você está pronto para transformar seu evento em algo verdadeiramente inesquecível? Aqui na <strong>{process.env.REACT_APP_NAME}</strong>, entendemos a importância de cada detalhe na criação de experiências marcantes. </p>
                        <h3>Planejamento Personalizado</h3>
                        <p>Nossa equipe dedicada trabalha lado a lado com você para criar um plano personalizado que atenda às suas necessidades específicas. Do conceito à execução, estamos comprometidos em garantir que cada detalhe seja perfeito.</p>
                        <h3>Experiências Únicas</h3>
                        <p>Acreditamos que cada evento deve ser único. Seja um casamento, uma conferência corporativa ou um evento social, nos esforçamos para adicionar elementos distintivos que tornem a experiência única e memorável para os participantes.</p>
                        <h3>Profissionais Experientes:</h3>
                        <p>Nossa equipe é composta por profissionais experientes que entendem a importância de um evento bem-sucedido. Gerenciamos todos os aspectos, desde a logística até a coordenação no dia do evento, para garantir um resultado impecável.</p>
                    </>
                )}
            </Prose>
        </Container>
    );
};

export default EventsContent