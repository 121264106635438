import React from 'react'

import GrowthChartIcon from '../assets/images/icons/growth-chart-icon.svg'
import MegaphoneIcon from '../assets/images/icons/megaphone-icon.svg'
import SocialMediaIcon from '../assets/images/icons/social-media-icon.svg'
import WebsiteIcon from '../assets/images/icons/website-icon.svg'
import OutddoorIcon from '../assets/images/icons/outdoor-icon.svg'
import MicrophoneIcon from '../assets/images/icons/microphone-icon.svg'
import RadioIcon from '../assets/images/icons/radio-icon.svg'
import BusinessAnalysisIcon from '../assets/images/icons/business-analysis-icon.svg'
import InterviewIcon from '../assets/images/icons/interview-icon.svg'
import TeamIcon from '../assets/images/icons/team-icon.svg'
import PartyIcon from '../assets/images/icons/party-icon.svg'
import LandingPageIcon from '../assets/images/icons/landing-page-icon.svg'
import WomanIcon from '../assets/images/icons/woman-icon.svg'

import EventsContent from '../data/services/events';
import SocialMediaContent from '../data/services/social-media';
import PaidTrafficContent from '../data/services/paid-traffic';
import WebsitesContent from '../data/services/websites';
import LandingPageContent from '../data/services/landing-page';
import CarSoundContent from '../data/services/car-sound';
import OutdoorContent from '../data/services/outdoor';
import LocutionContent from '../data/services/locution';
import RadioContent from '../data/services/radio';
import BusinessAnalysisContent from '../data/services/business-analytis';
import PromoterContent from '../data/services/promoters';
import RentContent from '../data/services/rent';

export interface Service {
    title: string
    slogan?: string | null
    description: string
    icon?: string | null
    category: string
    slug?: string
    content?: JSX.Element | string | null,
    url?: string,
}

const services: Service[] = [
    {
        title: 'services.list.social-media.title',
        description: 'services.list.social-media.description',
        icon: SocialMediaIcon,
        category: "online",
        slug: 'socialMedia',
        content: <SocialMediaContent />,
        url: 'midias-digitais'
    },
    {
        title: 'services.list.paid-traffic.title',
        description: 'services.list.paid-traffic.description',
        icon: GrowthChartIcon,
        category: "online",
        slug: 'paidTraffic',
        content: <PaidTrafficContent />,
        url: 'trafego-pago',
    },
    {
        title: 'services.list.websites.title',
        description: 'services.list.websites.description',
        icon: WebsiteIcon,
        category: "online",
        slug: 'websites',
        content: <WebsitesContent />,
        url: 'criacao-de-sites',
    },
    {
        title: 'services.list.lp.title',
        description: 'services.list.lp.description',
        icon: LandingPageIcon,
        category: "online",
        slug: 'landingPages',
        content: <LandingPageContent />,
        url: 'landing-pages',
    },
    {
        title: 'services.list.car-sound.title',
        slogan: 'services.list.car-sound.slogan',
        description: 'services.list.car-sound.description',
        icon: MegaphoneIcon,
        category: "offline",
        slug: 'carSound',
        content: <CarSoundContent />,
        url: 'carro-de-som',
    },
    {
        title: 'services.list.outdoor.title',
        description: 'services.list.outdoor.description',
        icon: OutddoorIcon,
        category: "offline",
        slug: 'outdoor',
        content: <OutdoorContent />,
        url: 'outdoor',
    },
    {
        title: 'services.list.locution.title',
        description: 'services.list.locution.description',
        icon: MicrophoneIcon,
        category: "offline",
        slug: 'locution',
        content: <LocutionContent />,
        url: 'locucao',
    },
    {
        title: 'services.list.radio.title',
        description: 'services.list.radio.description',
        icon: RadioIcon,
        category: "offline",
        slug: 'radio',
        content: <RadioContent />,
        url: 'radio',
    },
    {
        title: 'services.list.promoters.title',
        description: 'services.list.promoters.description',
        icon: WomanIcon,
        category: "offline",
        slug: 'promoters',
        content: <PromoterContent />,
        url: 'promoters',
    },
    {
        title: 'services.list.business-analysis.title',
        description: 'services.list.business-analysis.description',
        icon: BusinessAnalysisIcon,
        category: "consulting",
        slug: 'businessAnalysis',
        content: <BusinessAnalysisContent />,
        url: 'consultoria',
    },
    {
        title: 'services.list.rent.title',
        description: 'services.list.rent.description',
        icon: InterviewIcon,
        category: "events",
        slug: 'rent',
        content: <RentContent />,
        url: 'locacao',
    },
    {
        title: 'services.list.events.title',
        description: 'services.list.events.description',
        icon: PartyIcon,
        category: "events",
        slug: 'events',
        content: <EventsContent />,
        url: 'eventos',
    },
];

export default services;