import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import TitleCol from '../components/TitleCol';

import LojasCemLogo from '../assets/images/testimonials/lojas-cem-logo.png';
import CasasBahiaLogo from '../assets/images/testimonials/casas-bahia-logo.png';
import CaoaLogo from '../assets/images/testimonials/caoa-logo.png';
import ToGoLogo from '../assets/images/testimonials/togo-logo.png';
import TimLogo from '../assets/images/testimonials/tim-logo.webp';
import ChevroletLogo from '../assets/images/testimonials/chevrolet-logo.webp';
import HolinessLogo from '../assets/images/testimonials/holiness-logo.webp';

interface Testimonial {
	name: string;
	content: string;
	company: string;
	img?: string;
}

const Testimonials = () => {

	const { t } = useTranslation();
	
	const testimonials: Testimonial[] = [
		{
			name: t('testimonials.list.quote2.author'),
			content: t('testimonials.list.quote2.quote'),
			company: t('testimonials.list.quote2.company'),
			img: CaoaLogo,
		},
		{
			name: t('testimonials.list.quote3.author'),
			content: t('testimonials.list.quote3.quote'),
			company: t('testimonials.list.quote3.company'),
			img: LojasCemLogo,
		},
		{
			name: t('testimonials.list.quote4.author'),
			content: t('testimonials.list.quote4.quote'),
			company: t('testimonials.list.quote4.company'),
			img: CasasBahiaLogo,
		},
		{
			name: t('testimonials.list.quote1.author'),
			content: t('testimonials.list.quote1.quote'),
			company: t('testimonials.list.quote1.company'),
			img: ToGoLogo,
		},
		{
			name: t('testimonials.list.quote5.author'),
			content: t('testimonials.list.quote5.quote'),
			company: t('testimonials.list.quote5.company'),
			img: ChevroletLogo,
		},
		{
			name: t('testimonials.list.quote6.author'),
			content: t('testimonials.list.quote6.quote'),
			company: t('testimonials.list.quote6.company'),
			img: HolinessLogo,
		},
		{
			name: t('testimonials.list.quote7.author'),
			content: t('testimonials.list.quote7.quote'),
			company: t('testimonials.list.quote7.company'),
		},
		{
			name: t('testimonials.list.quote8.author'),
			content: t('testimonials.list.quote8.quote'),
			company: t('testimonials.list.quote8.company'),
			img: TimLogo,
		}
	];

	return (
		<div className="w-full flex items-center justify-center gap-8 lg:gap-16 flex-col mt-16 lg:mt-20" id="testimonials">
			<Container>
				<TitleCol
					title={t('testimonials.title')}
					subtitle={t('testimonials.subtitle')}
					className="max-lg:p-4"
				/>
			</Container>
			
			<div className="bg-gray-100 grid lg:grid-cols-2">
				{testimonials.map((testimonial, index) => (
					<div
						key={index}
						className={`${(index % 4 !== 3) ? 'lg:border-r' : ''} 
									${index > 1 ? 'lg:border-t' : ''} 
									max-lg:border-b border-gray-300 w-full flex items-center justify-center 
									flex-col gap-2 p-8 lg:min-h-[280px]`}
					>
						{testimonial.img ? (
							<figure className="w-32 h-16">
								<img
									src={testimonial.img}
									alt={testimonial.name}
									className="w-full h-full object-contain"
									itemProp="image"
								/>
							</figure>
						) : (
							<div className="font-bold text-xl text-slate-700">{ testimonial.company }</div>
						)}
						<blockquote
							className="flex flex-col items-center justify-center gap-2 text-center"
							itemProp="review"
							itemScope
							itemType="https://schema.org/Review"
						>
							<span className="hidden" itemProp="reviewRating">5</span>
							<p itemProp="reviewBody" className="text-gray-800 text-lg italic">{testimonial.content}</p>
							<cite itemProp="author" className="text-gray-500 font-semibold not-italic">{testimonial.name}</cite>
						</blockquote>
					</div>
				))}
			</div>
		</div>
	)
}

export default Testimonials;