import React from 'react';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import Prose from '../../components/page/Prose';
import Container from '../../components/Container';
import Testimonials from '../../partials/Testimonials';
import Features from '../../partials/Features';
import Brands from '../../partials/Brands';
import Title from '../../components/Title';
import TitleRow from '../../components/TitleRow';
import Gift from '../../partials/Gift';
import TitleCol from '../../components/TitleCol';

import Slider1 from '../../assets/images/slides/carro-de-som-slider-4.webp';
import Slider2 from '../../assets/images/slides/carro-de-som-slider-1.webp';
import Slider3 from '../../assets/images/slides/carro-de-som-slider-2.webp';
import Slider4 from '../../assets/images/slides/carro-de-som-slider-3.webp';

import { Pagination, Autoplay } from 'swiper/modules';
import {Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import Contact from '../../partials/Contact';

const CarSoundContent = () => {
    const lang = i18n.language;
    const { t } = useTranslation();

    const videos = [
        {
            youtubeCode: 'JFQePP2ZRrc',
        },
        {
            youtubeCode: '6VXoq2jJHPs',
        },
    ]

    return (
        <>
            <Container>
                <div className="flex items-center justify-center mt-8 flex-col gap-8 lg:gap-16">
                    <div className="grid lg:grid-cols-2 gap-4 lg:gap-8">
                        <div className="bg-gray-700 aspect-w-16 aspect-h-9 rounded-md overflow-hidden">
                            <iframe
                                className="h-full w-full"
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/slXrHTNf-LA?si=gWXkC4VWoxNv_wkI&amp;controls=0"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="p-4 flex items-center justify-center flex-col">
                            <p className="text-left text-lg lg:text-2xl font-roboto">{t('services.list.car-sound.excerpt')}</p>
                        </div>
                    </div>
                </div>
            </Container>

            <div className="w-full relative">
                <Container>
                    <Gift whatsAppMsg={`Olá!+Gostaria+de+conseguir+o+desconto+de+33%+no+serviço+de+carro+de+som`} />
                </Container>
                <div className="absolute left-0 -bottom-32 w-full h-64 bg-slate-800 -z-10 max-lg:hidden"></div>
            </div>
    
            <div className="w-full bg-slate-800 py-8 lg:py-16 xl:py-24">
                <TitleCol
                    title={t('services.list.car-sound.brands.title')}
                    mode="dark"
                    className="mb-4 lg:mb-8 uppercase"
                />
                <Brands />
            </div>

            <TitleRow
                title={t('services.list.car-sound.features.title')}
                subtitle={t('services.list.car-sound.features.subtitle')}
            />
            <Features darkBg={false} />

            <div className="w-full mt-4 lg:mt-8">
                <Swiper
                    modules={[Pagination, Autoplay]}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false
                    }}
                    slidesPerView={1}
                    pagination
                    loop={true}
                >
                    <SwiperSlide><img className="w-full" src={Slider1} alt={process.env.REACT_APP_NAME} /></SwiperSlide>
                    <SwiperSlide><img className="w-full" src={Slider2} alt={process.env.REACT_APP_NAME} /></SwiperSlide>
                    <SwiperSlide><img className="w-full" src={Slider3} alt={process.env.REACT_APP_NAME} /></SwiperSlide>
                    <SwiperSlide><img className="w-full" src={Slider4} alt={process.env.REACT_APP_NAME} /></SwiperSlide>
                </Swiper>
            </div>

            <TitleRow
                title={t('services.list.car-sound.benefits.title')}
                subtitle={t('services.list.car-sound.benefits.subtitle')}
                className="mt-4 lg:mt-8"
            />
            
            <Container>
                <Prose>
                    {lang === 'en' ? (
                        <>
                            <h2>Amplifying Your Message on the Streets</h2>
                            <p>Want to take your message directly to the public on the streets? Here at <strong>WD Publicidade</strong>, we offer car audio services to ensure that your message is heard throughout the community.</p>
                            <h3>Street Impact</h3>
                            <p>Our car audio systems are equipped with high-quality audio to ensure that your message is clear and impactful. Reach different areas of the city and promote events, special offers, or institutional messages effectively.</p>
                            <h3>Customized Script</h3>
                            <p>We work together to create a customized script that conveys your message engagingly. From promotional announcements to calls to action, we ensure that every word resonates with your target audience.</p>
                            <h3>Strategic Coverage</h3>
                            <p>We strategically plan the routes of the car audio services to reach areas with a high concentration of the audience. Whether for promotional events, political campaigns, or local business outreach, we provide maximum visibility on the streets.</p>
                            <ol>
                                <li>The client makes contact, requesting a quote;</li>
                                <li>The customer closes the contract because we have the best price in relation to cost-benefit;</li>
                                <li>We collect information to guide the action plan;</li>
                                <li>We prepare strategic planning;</li>
                                <li>We created the text for Spot/Jingle production and recorded it;</li>
                                <li>Within up to 30 hours, the customer will be able to listen to the audio for final approval;</li>
                                <li>With the approved Spot, our vehicles are served on the scheduled dates and times;</li>
                                <li>The car starts at the scheduled times and days;</li>
                                <li>The driver records the mileage and start/end time;</li>
                                <li>The driver does a photo check-in, collects the visa from the person responsible and starts the journey;</li>
                                <li>The customer can monitor the vehicle photographically at any time, in addition to having contact with the driver through our app, facilitating communication and changing strategy quickly and efficiently;</li>
                                <li>For greater transparency, a company representative is authorized to accompany each contracted car;</li>
                                <li>After the end of the working day, we collect information and register the data needed by the driver, which together with the application records are released in a report, which is oriented towards our client;</li>
                                <li>We collect feedback so that we can continue with the action or devise new strategies.</li>
                            </ol>
                        </>
                    ) : (
                        <>
                            <h2>Amplificando Sua Mensagem nos bairros</h2>
                            <p>Quer levar a sua mensagem diretamente para o público nas ruas? Aqui na <strong>WD Publicidade</strong>, oferecemos serviços de carro de som para garantir que a sua mensagem seja ouvida em toda a comunidade.</p>
                            <h3>Impacto nas Ruas</h3>
                            <p>Nossos carros de som são equipados com sistemas de áudio de alta qualidade para garantir que a sua mensagem seja clara e impactante. Alcance diferentes áreas da cidade e promova eventos, ofertas especiais ou mensagens institucionais com eficácia.</p>
                            <h3>Roteiro Personalizado</h3>
                            <p>Trabalhamos em conjunto para criar um roteiro personalizado que transmita sua mensagem de maneira envolvente. Desde anúncios promocionais até chamadas para ação, garantimos que cada palavra ressoe com o seu público-alvo.</p>
                            <h3>Cobertura Estratégica</h3>
                            <p>Planejamos estrategicamente as rotas dos carros de som para atingir áreas com alta concentração de público. Seja para eventos promocionais, campanhas políticas ou divulgação de negócios locais, proporcionamos a máxima visibilidade nas ruas.</p>
                            <h3>Como funciona o nosso processo de Carro de Som?</h3>
                            <ol>
                                <li>O cliente faz o contato, solicitando o orçamento;</li>
                                <li>O cliente fecha o contrato por termos o melhor preço em relação ao custo-benefício;</li>
                                <li>Coletamos informações para nortear o plano de ação;</li>
                                <li>Elaboramos o planejamento estratégico;</li>
                                <li>Criamos o texto para produção de Spot/Jingle e gravamos;</li>
                                <li>Em até 30 horas, cliente poderá ouvir o áudio para a aprovação final;</li>
                                <li>Com o Spot aprovado, nossos veículos são veiculados nas datas e horários agendados;</li>
                                <li>O carro inicia nos horários e dias agendados;</li>
                                <li>O motorista registra a quilometragem e horário de início/fim;</li>
                                <li>O motorista faz checkin fotográfico, coleta o visto do responsável e dá início à veiculação;</li>
                                <li>O cliente pode monitorar o veículo fotograficamente a hora que desejar, além de ter contato com o motorista pelo nosso aplicativo, facilitando a comunicação e alteração de estratégia de maneira rápida e eficiente;</li>
                                <li>Para maior transparência, é autorizado um representante da empresa para acompanhar cada carro contratado;</li>
                                <li>Após todo fim de expediente, coletamos informações e protocolamos os dados preenchidos pelo motorista, que junto com os registros do app são lançados em um relatório, que é encaminhado para nosso cliente;</li>
                                <li>Coletamos os feedbacks para que continuamos com a ação ou tracemos novas estratégias.</li>
                            </ol>
                        </>
                    )}
                </Prose>
            </Container>

            <Container>
                <div className="grid md:grid-cols-2 gap-4 lg:gap-8 mt-8 lg:mt-20">
                    {videos.map((video, index) => (
                        <div key={index} className="aspect-w-16 aspect-h-9 rounded-md overflow-hidden">
                            <iframe
                                className="h-full w-full"
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${video.youtubeCode}?si=gWXkC4VWoxNv_wkI&amp;controls=0`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))}
                </div>
            </Container>

            <Testimonials />

            <Container>
                <Contact />
            </Container>
        </>
    );
};

export default CarSoundContent;
