import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import services from '../data/services'
import Container from '../components/Container'

const Service: React.FC = () => {
    const params = useParams()
    const slug = params.slug as string
    const { t } = useTranslation();
    const service = services.find(service => service.url === slug)

    if (!service) {
        return (
            <div className="my-32 lg:my-64">
                ERRO!
            </div>
        )
    }

    return (
        <div className="mt-32 mb-32">
            <Helmet>
                <title>{t(service.title)} - {process.env.REACT_APP_NAME}</title>
                {service.description && <meta name="description" content={t(service.description)} />}
            </Helmet>
            <div className="w-full flex items-center justify-center flex-col gap-8 lg:gap-16">
                <Container>
                    <h1 className="text-center text-2xl md:text-4xl lg:text-6xl font-bold font-sans text-red-500 flex flex-col items-center justify-center gap-2">
                        {service.slogan
                            ? <>
                                {t(service.slogan)}
                                <span className="text-gray-500 text-lg uppercase p-2 bg-gray-100 rounded-md">{t(service.title)}</span>
                            </>
                            : <>{t(service.title)}</>  
                        }
                    </h1>
                </Container>

                {service.content}
            </div>
        </div>
    )
}

export default Service