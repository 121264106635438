import React from 'react'

type Props = {
	type: "success" | "error" | "warning" | "info" | "default";
	title: string;
	description?: string;
}

interface Colors {
	[key: string]: {
		bg: string;
		text: string;
		title: string;
	};
}

const FormStatus = (props: Props) => {
	const colors:Colors = {
		success: {
			bg: "bg-green-500",
			text: "text-gray-800",
			title: "text-black",
		},
		error: {
			bg: "bg-red-500",
			text: "text-gray-800",
			title: "text-black",
		},
		warning: {
			bg: "bg-yellow-500",
			text: "text-gray-800",
			title: "text-black",
		},
		info: {
			bg: "bg-blue-500",
			text: "text-gray-800",
			title: "text-black",
		},
		default: {
			bg: "bg-white",
			text: "text-gray-800",
			title: "text-orange-500",
		},
	}

	return (
		<div className={`absolute top-0 p-8 text-center left-0 w-full h-full bg-green-500 flex flex-col items-center justify-center gap-2 rounded z-20`}>
			<h3 className="text-xl lg:text-2xl text-black font-bold uppercase">{props.title}</h3>
			{props.description && (
				<p className="text-md md:text-lg text-gray-800">{props.description}</p>
			)}
		</div>
	)
}

FormStatus.defaultProps = {
	type: "default",
}

export default FormStatus;