import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeHeader from '../partials/HomeHeader';
import About from '../partials/About';
import Services from '../partials/Services';
import Testimonials from '../partials/Testimonials';
import Contact from '../partials/Contact';
import Features from '../partials/Features';
import { Helmet } from 'react-helmet';

export default function Home () {

	const { t } = useTranslation();
 	
	return (
		<main id="main">
            <Helmet>
            	<title>{t('agency')} {process.env.REACT_APP_NAME}</title>
                <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
            </Helmet>
			<HomeHeader />
			<About />
			<Features darkBg={true} />
			<Services />
			<Testimonials />
			<Contact />
		</main>
	);
}
